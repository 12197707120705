import { Body, Button, H1, Header, Text } from "native-base";
import React, { useState } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { isWeb, minDesktopWidth } from "@/components/globalUtils";
import NewTestButton from "../shared/NewTestButton";

const styles = StyleSheet.create({
  button: {
    height: 48,
    marginLeft: 9,
    paddingLeft: 20,
    paddingRight: 20,
  },
  mobileButton: {
    height: 32,
    marginLeft: 9,
  },

  icon: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  mobileIcon: {
    paddingVertical: 4,
  },
  buttonText: { padding: 0, fontSize: 15, fontWeight: "500" },
});

const DashboardHeader = ({ navigation }) => {
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const createEvent = () => {
    navigation.navigate("CreateEvent");
  };

  return (
    <>
      <Header
        style={{
          width: "100%",
          paddingTop: 0, //isWeb ? 0 : Constants.statusBarHeight, // TODO: is statusBarHeight android specific?
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: "white",
        }}
      >
        <Body style={{ flex: 1, width: "100%" }}>
          <View
            style={{
              width: "100%",
              marginTop: isWeb ? 20 : 8, //Mobile only?
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1, flexDirection: "row" }}>
              <H1
                style={{
                  flexGrow: 0,
                  flexShrink: 0,
                  fontSize: isMobileUX ? 24 : 32,
                  fontWeight: "800",
                  paddingRight: 6,
                  paddingLeft: isMobileUX ? 0 : 20,
                }}
              >
                My Events
              </H1>
              <TouchableOpacity activeOpacity={1} onPress={createEvent}>
                <Ionicons
                  name="add-circle"
                  size={30}
                  color="#F84123"
                  style={{ flex: 0, alignSelf: "center" }}
                />
              </TouchableOpacity>
            </View>

            {!isMobileUX && (
              <NewTestButton
                style={isMobileUX ? styles.mobileButton : styles.button}
              />
            )}

            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                navigation.navigate("Profile");
              }}
            >
              <Ionicons
                name="person-outline"
                size={24}
                color="#3f5b7a"
                style={isMobileUX ? styles.mobileIcon : styles.icon}
              />
            </TouchableOpacity>

            {/* <Button
              rounded
              dark
              bordered
              block
              style={isMobileUX ? styles.mobileButton : styles.button}
              onPress={() => {
                navigation.navigate("Profile");
              }}
            >

              <Ionicons name="person" size={16} style={{ paddingHorizontal: 8 }} onPress={() => {
                navigation.navigate("Profile");
              }} />
            </Button> */}
          </View>
        </Body>
      </Header>
    </>
  );
};

export default DashboardHeader;
