import { Icon, Input, Item, Label, Text, Textarea } from "native-base";
import React, { useRef, useState } from "react";
import { Pressable } from "react-native";

import GlobalStyles, { StyleConstants } from "../globalStyle";

const AppInput = React.memo(
  ({
    textarea = false,
    style,
    label,
    labelStyle,
    inputStyle,
    text,
    placeholder,
    secureTextEntry,
    onPress,
    errorMessage,
    onSubmitOnEnter,
    onIconRightAction, //Used for custom right icon action
    iconRightName,
    iconRightColor,
    rightComponent,
    onItemPress,
    // selectedProps: selectedProps={ style, inputStyle, text, placeholder, secureTextEntry, onPress},
    ...extraProps
  }) => {
    const [isFocused, setIsFocused] = useState(false);
    // const i = useRef(null);
    const [isPasswordVisible, setIsPasswordVisible] = useState(
      secureTextEntry === true
    );

    if (onSubmitOnEnter && typeof onSubmitOnEnter === "function") {
      extraProps.onKeyPress = (e) => {
        if (e.key === "Enter") {
          onSubmitOnEnter();
        }
      };
    }

    const InputComponent = textarea ? Textarea : Input;

    return (
      <>
        <Item
          error
          rounded
          fixedLabel
          inlineLabel={!!label}
          focusable={false}
          accessible={false}
          style={[
            isFocused ? GlobalStyles.appItemFocused : GlobalStyles.appItem,
            style,
          ]}
          onPress={onItemPress}
        >
          {label && (
            <Label
              style={{
                marginLeft: 15,
                flex: 1,
                flexGrow: 1,
                color: "#000",
                ...labelStyle,
              }}
            >
              {label}
            </Label>
          )}
          <InputComponent
            pointerEvents={onItemPress ? "none" : null}
            rounded
            style={[
              isFocused ? GlobalStyles.appInputFocused : GlobalStyles.appInput,
              {
                width: "100%",
              },
              inputStyle,
            ]}
            {...extraProps}
            onPress={onItemPress}
            secureTextEntry={isPasswordVisible}
            placeholder={placeholder}
            onBlur={(ev) => {
              setIsFocused(false);
              extraProps.onBlur && extraProps.onBlur(ev);
            }}
            onFocus={(ev) => {
              setIsFocused(true);
              extraProps.onFocus && extraProps.onFocus(ev);
            }}
            // getRef={(input) => {
            //   i.current = input;
            // }}
          />
          {/* Password */}
          {secureTextEntry && (
            <Pressable
              onPress={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
            >
              <Icon
                active
                large
                style={[isFocused ? GlobalStyles.iconFocus : GlobalStyles.icon]}
                name={!isPasswordVisible ? "eye-off-outline" : "eye-outline"}
              />
            </Pressable>
          )}

          {!secureTextEntry && onIconRightAction && (
            <Pressable
              style={({ pressed }) => [{ opacity: pressed ? 0.3 : 1.0 }]}
              onPress={onIconRightAction}
            >
              <Icon
                active
                name={iconRightName}
                style={{ color: StyleConstants.defaultIconColorDark }}
              />
            </Pressable>
          )}

          {!secureTextEntry && !onIconRightAction && (
            <Icon
              active
              name={iconRightName}
              style={{ color: StyleConstants.defaultIconColorDark }}
            />
          )}

          {rightComponent && rightComponent}
        </Item>
        {!!errorMessage && (
          <Text style={GlobalStyles.appInputErrorMessage}>{errorMessage}</Text>
        )}
      </>
    );
  }
);

export default AppInput;
