import * as Clipboard from "expo-clipboard";
import { useClipboard } from "use-clipboard-copy";

import { Container, List, Text, Footer, Root, Toast } from "native-base";
import React, { useEffect, useState } from "react";
import {
  DeviceEventEmitter,
  Image,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import { useAuth } from "../../AuthContext";
import { deleteGuestById, fetchGuests } from "../../http/events";
import AppHeader, { onBackPressed } from "../../shared/AppHeader";
import DashboardContent from "../../shared/DashboardContent";
import GuestListItem from "../../shared/GuestListItem";
import OnboardingButton from "../../shared/OnboardingButton";
import ShareEventButton from "../../shared/ShareEventButton";
import analytics from "../../../analytics";
import { AppBackButton } from "../../shared/Buttons";
import { showActionSheet } from "../../Utils";

const localStyles = StyleSheet.create({
  actionButtonContainer: {
    // flexDirection: "row",
    // justifyContent: "space-between",
    paddingHorizontal: 16,
    alignSelf: "center",
    width: "100%",
    // height: 50,
    paddingHorizontal: 20,
    maxWidth: 800,
    paddingVertical: 20,
  },
});

const arrayToCsv = (headers, data) => {
  const csvRows = [];
  // getting headers.
  const headerValues = headers.map((header) => header.label);
  csvRows.push(headerValues.join(","));
  // Push into array as comma separated values.
  for (const row of data) {
    const rowValues = headers.map((header) => {
      const escaped = ("" + row[header.key]).replace(/"/g, '\\"'); // To replace the unwanted quotes.
      return `"${escaped}"`; // To escape the comma in a address like string.
    });
    csvRows.push(rowValues.join(","));
  }
  return csvRows.join("\n"); // To enter the next rows in the new line '\n'
};

const GuestList = ({ route, navigation }) => {
  const { slug } = route.params;
  const [event, setEvent] = useState({ slug });
  const [loading, setloading] = useState(true);
  const { broadcaster, custom_domain } = useAuth();

  const domain = custom_domain || `https://evt.live/`;
  const { alias, title } = event;
  const url = alias ? `${domain}${alias}` : `${domain}${broadcaster}/${slug}`;

  const downloadList = (csvContent, filename) => {
    if (Platform.OS === "web") {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // In case of IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } else {
      const RNFS = require("react-native-fs");
      const path =
        Platform.OS === "android"
          ? RNFS.DownloadDirectoryPath + "/guestlist.csv"
          : RNFS.DocumentDirectoryPath + "/guestlist.csv";

      RNFS.writeFile(path, csvContent, "utf8")
        .then((success) => {
          Toast.show({
            text: "Guest list is saved to Downloads",
            position: "bottom",
            type: "success",
            duration: 1000,
          });
        })
        .catch((err) => {
          Toast.show({
            text: "Could not save the file. Please try again on web",
            position: "bottom",
            type: "danger",
            duration: 5000,
          });
        });
    }
  };

  useEffect(() => {
    const fetchAsync = async () => {
      try {
        const myEvent = await fetchGuests(slug);
        if (!myEvent) {
          throw new Error("Not found");
        }
        setEvent(myEvent);
        setloading(false);
      } catch (err) {
        console.error(err);

        Toast.show({
          text: "Could not load the event",
          position: "top",
          type: "danger",
          duration: 5000,
        });
        navigation.navigate("EventList");
      }
    };

    if (broadcaster) {
      fetchAsync();
      // console.log("SUBSCRIBE FOR UPDATES, ", broadcaster, slug)
      // return subscribeToEventUpdates(broadcaster, slug, (updatedEvent) => {
      //   console.log(updatedEvent)
      //   setEvent({ ...event, ...updatedEvent });
      // });
    }
  }, [slug, broadcaster]);

  useEffect(() => {
    const sub = DeviceEventEmitter.addListener("guest-add", ({ guest }) => {
      setEvent({ ...event, guests: [guest, ...event.guests] });
    });
    return () => {
      sub.remove();
    };
  });

  const deleteGuestAction = async ({ id }) => {
    showActionSheet(
      `Remove the guest from the list?`,
      async () => {
        await deleteGuestById(id);
        setEvent({
          ...event,
          guests: event.guests.filter((g) => g.id !== id),
        });
        analytics.track("Delete Guest", {});
      },
      "Delete Guest"
    );
  };

  const guests = (event.guests || []).map((guest) => {
    let status = "";
    let color = "";
    switch (true) {
      case !!guest.was_live:
        color = "#48BB78";
        status = "Joined Live";
        break;
      case !!guest.was_past:
        color = "#2F855A";
        status = "Watched Replay";
        break;
      case !!guest.was_upcoming:
        color = "#A0AEC0";
        status = "Visited page";
        break;
      case guest.source === "invitation" &&
        !guest.was_upcoming &&
        !guest.was_live &&
        !guest.was_past:
        color = "#3182CE";
        status = "Invited";
      default:
        break;
    }

    return { ...guest, status, color };
  });
  const csvHeader = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    // todo: unsubscribed? bounced? invited? etc..
  ];

  const guestsCopy = arrayToCsv(csvHeader, guests);

  return (
    <Root>
      <Container>
        <AppHeader
          title={
            guests.length === 0
              ? "Invite"
              : `${guests.length} ${guests.length > 1 ? "guests" : "guest"}`
          }
          leftButtonComponent={
            <AppBackButton
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                } else {
                  navigation.navigate("EventEdit", route.params);
                }
              }}
            />
          }
          hasLeftButton={true}
        />
        <DashboardContent>
          {loading && <AppActivityIndicator />}
          {!loading && (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                maxWidth: 720,
                paddingHorizontal: 16,
                alignSelf: "center",
                width: "100%",
              }}
            >
              <View style={{ flex: 1 }}>
                {!guests.length && (
                  <>
                    <View
                      style={{
                        flex: 1,
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        source={require("../../../assets/guestlistEmpty.png")}
                        width="195"
                        height="180"
                        style={{
                          width: 195,
                          height: 180,
                          alignSelf: "center",
                        }}
                      />
                    </View>
                    <View style={{ flexShrink: 0, paddingVertical: 20 }}>
                      <Text
                        style={{
                          color: "#000000",
                          fontSize: 18,
                          fontWeight: "800",
                          width: "100%",
                          paddingBottom: 10,
                          paddingHorizontal: 40,
                        }}
                      >
                        Start Inviting Guests
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          width: "100%",
                          paddingHorizontal: 40,
                        }}
                      >
                        Invite guests by email or just share your Event Link
                        with them!
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          width: "100%",
                          marginTop: 20,
                          paddingHorizontal: 40,
                        }}
                      >
                        All guests who sign the guestbook or opt in for
                        reminders will appear here
                      </Text>
                    </View>
                  </>
                )}

                {!!guests.length && (
                  <List style={{ flexGrow: 1 }}>
                    {guests.map((guest) => (
                      <GuestListItem
                        guest={guest}
                        key={guest.id || guest.email}
                        onDelete={() => deleteGuestAction(guest)}
                      />
                    ))}
                  </List>
                )}
              </View>
            </View>
          )}
        </DashboardContent>
        <Footer>
          <View style={localStyles.actionButtonContainer}>
            <ShareEventButton url={url} title={title} text="Share link" />

            {!!guests.length &&
              event.event_status &&
              event.event_status !== "upcoming" && (
                <OnboardingButton
                  text="Download Guest List"
                  style={{ marginTop: 0 }}
                  dark
                  bordered
                  icon="download"
                  onPress={() => {
                    downloadList(
                      guestsCopy,
                      title ? `Guests ${title}.csv` : `Guests.csv`
                    );
                  }}
                />
              )}
            {event.event_status &&
              (event.event_status !== "past" ||
                event?.event_privacy === "invite_only") && (
                <OnboardingButton
                  text="Invite by Email"
                  style={{ marginTop: 0 }}
                  dark
                  bordered
                  icon="mail-outline"
                  onPress={() => {
                    navigation.navigate("GuestAdd", {
                      slug,
                    });
                  }}
                />
              )}
          </View>
        </Footer>
      </Container>
    </Root>
  );
};

export default GuestList;
