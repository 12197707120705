import { Text } from "native-base";
import React from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 25,
  },
  bullet: {
    width: 10,
    marginLeft: 20,
    marginRight: 10,
  },
  bulletText: {
    marginRight: 40,
    fontSize: 18,
  },
});

const Bullet = ({ children, condensed, textStyle, isChecked }) => (
  <View style={[styles.row, condensed && { marginBottom: 4 }]}>
    <View style={styles.bullet}>
      <Text>{isChecked ? "\u2713" : "\u2022"} </Text>
    </View>
    <Text style={[styles.bulletText, condensed && { fontSize: 14 }, textStyle]}>
      {children}
    </Text>
  </View>
);

export default Bullet;
