import { Container, Root } from "native-base";
import React from "react";
import { Pressable, View } from "react-native";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import { useAuth } from "@/components/AuthContext";
import useSubscribeToEventUpdates from "@/components/hooks/useSubscribeToEventUpdates";
import AppHeader from "@/components/shared/AppHeader";
import { AppBackButton } from "@/components/shared/Buttons";
import DashboardContent from "@/components/shared/DashboardContent";
import { Ionicons } from "@expo/vector-icons";

const EventSubScreen = ({
  navigation,
  route,
  backButtonLite,
  contentComponent: ContentComponent,
  title = "Settings",
}) => {
  const { slug } = route.params;
  const { broadcaster } = useAuth();

  const passedEvent = route.params.event && route.params.event.slug;

  const { event, loading, error } = useSubscribeToEventUpdates(
    passedEvent ? route.params.event : { broadcaster, slug }
  ); //!passedEvent

  const back = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("EventEdit", route.params);
    }
  };

  return (
    <Root>
      <Container>
        <AppHeader
          // title={title}
          leftButtonComponent={
            backButtonLite ? (
              <Pressable onPress={back}>
                <Ionicons
                  name="ios-close-outline"
                  size={30}
                  style={{ opacity: 0.3 }}
                  color="#787878"
                />
              </Pressable>
            ) : (
              <AppBackButton onPress={back} />
            )
          }
          hasLeftButton={true}
          hasRightButton={true}
          renderRightButton={() => {
            return loading && <AppActivityIndicator />;
          }}
        />

        <DashboardContent>
          <View
            style={{
              flex: 1,
              flexGrow: 1,
              marginHorizontal: 16,
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <View style={{ flex: 1, maxWidth: 640 }}>
              <ContentComponent event={event} />
            </View>
          </View>
        </DashboardContent>
      </Container>
    </Root>
  );
};

export default EventSubScreen;
