import * as Clipboard from "expo-clipboard";
import * as WebBrowser from "expo-web-browser";
import React, { useState } from "react";
import { Pressable, StyleSheet, View, useWindowDimensions } from "react-native";
import { useClipboard } from "use-clipboard-copy";
import { Toast, Icon } from "native-base";
import { Ionicons } from "@expo/vector-icons";

import { isWeb, minDesktopWidth } from "../globalUtils";
import AppInput from "./AppInput";
import Link from "./Link";
import FormSubtitle from "./FormSubtitle";
import { resetEvent } from "../http/events";

const RtmpForm = ({
  stream_key,
  event_status,
  alias,
  slug,
  broadcaster,
  is_test,
}) => {
  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  });
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const [useRtmps, setuseRtmps] = useState(true);
  const [showGoPro, setShowGoPro] = useState(false);

  const domain = `https://evt.live/`;
  const url = alias ? `${domain}${alias}` : `${domain}${broadcaster}/${slug}`;

  const rtmpServer = "rtmp://go.eventlive.pro/live";
  const rtmpsServer = "rtmps://go.eventlive.pro:443/live";
  const server = useRtmps ? rtmpsServer : rtmpServer;
  const serverFull = `${server}/${stream_key}`;

  const clipBoardCallback = (value) => () => {
    if (isWeb) {
      clipboard.copy(value);
    } else {
      Clipboard.setString(value);
    }
    Toast.show({
      text: "Copied",
      position: isMobileUX ? "bottom" : "top-right",
      type: "success",
    });
  };

  const openLink = async (url) => {
    await WebBrowser.openBrowserAsync(url);
  };

  const openHelp = async () => {
    await openLink("https://help.eventlive.pro/en/category/how-to-12dn5vg/");
  };

  const resetVideo = () => {
    resetEvent(slug);
  };

  return (
    <View style={{ maxWidth: 500 }}>
      <FormSubtitle>{!showGoPro ? "RTMP Server" : "RTMP URL"}</FormSubtitle>

      {!showGoPro && (
        <>
          <AppInput
            value={server}
            disabled={true}
            labelStyle={{ textAlign: "right" }}
            iconRightName={"copy-outline"}
            onIconRightAction={clipBoardCallback(server)}
          />

          <FormSubtitle>Your Event’s Private Stream Key</FormSubtitle>
          <AppInput
            value={stream_key}
            disabled={true}
            labelStyle={{ textAlign: "right" }}
            iconRightName={"copy-outline"}
            onIconRightAction={clipBoardCallback(stream_key)}
          />
        </>
      )}

      {showGoPro && (
        <>
          <AppInput
            value={serverFull}
            disabled={true}
            labelStyle={{ textAlign: "right" }}
            iconRightName={"copy-outline"}
            onIconRightAction={clipBoardCallback(serverFull)}
          />
        </>
      )}

      <FormSubtitle>
        Link to watch your {is_test ? `test` : `event`}
      </FormSubtitle>
      <AppInput
        value={url}
        disabled={true}
        labelStyle={{ textAlign: "right" }}
        rightComponent={
          <>
            <Pressable
              style={({ pressed }) => [{ opacity: pressed ? 0.3 : 1.0 }]}
              onPress={clipBoardCallback(url)}
            >
              <Icon active name={"copy-outline"} style={{ color: "#8B8B8D" }} />
            </Pressable>
            <Pressable
              style={({ pressed }) => [{ opacity: pressed ? 0.3 : 1.0 }]}
              onPress={() => {
                openLink(url);
              }}
            >
              <Icon active name={"open-outline"} style={{ color: "#8B8B8D" }} />
            </Pressable>
          </>
        }
      />

      {event_status === "past" && is_test && (
        <View>
          <Link onPress={resetVideo}>
            <Ionicons name="ios-trash" size={14} color="#F84123" /> Reset Test
            Video
          </Link>
        </View>
      )}

      <View style={{ display: "flex", flexDirection: "row" }}>
        <Link
          onPress={() => setShowGoPro(!showGoPro)}
          style={{ alignSelf: "flex-start", flex: 1 }}
        >
          {showGoPro ? "RTMPs URL & Stream Key" : "GoPro / Larix settings"}
        </Link>

        <Link
          onPress={openHelp}
          style={{
            alignSelf: "flex-end",
            textAlign: "right",
            flexShrink: 0,
            flexGrow: 0,
          }}
        >
          Learn more
        </Link>
      </View>

      <View>
        <Link
          onPress={() => {
            setuseRtmps(!useRtmps);
            setShowGoPro(false);
          }}
        >
          {useRtmps ? "SlingStudio / RTMP" : "RTMPs"}
        </Link>
      </View>
    </View>
  );
};

export default RtmpForm;
