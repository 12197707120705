import * as Clipboard from "expo-clipboard";
import { Formik, Form } from "formik";
import { Text, Toast } from "native-base";
import React, { Suspense, useEffect } from "react";
import { Platform, View } from "react-native";

import { updateEventAlias } from "../http/events";
import { AliasSchema } from "../screens/Schemas";
import AppInput from "../shared/AppInput";
import FormSubtitle from "../shared/FormSubtitle";
import AutoSave from "./AutoSave";
import EventVideoOptionsForm from "./EventVideoOptionsForm";

const EventPersonalizeUpload = React.lazy(() =>
  import("./EventPersonalizeUpload")
);

const EventPersonalizeForm = ({ event }) => {
  const { slug, alias, is_test } = event;

  const copy = (alias) => {
    Clipboard.setString(`https://evt.live/${alias}`);

    Toast.show({
      text: "Copied",
      position: "bottom",
      type: "success",
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          alias: alias || "",
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            await updateEventAlias({ slug, ...values });
            actions.setSubmitting(false);
          } catch (err) {
            actions.setSubmitting(false);
          }
        }}
        validationSchema={AliasSchema}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {(f) => (
          <>
            <AutoSave {...f} />

            {!is_test && (
              <>
                <FormSubtitle>
                  Edit Link (Do not change after sharing with guests)
                </FormSubtitle>

                <AppInput
                  onChangeText={f.handleChange("alias")}
                  onBlur={f.handleBlur("alias")}
                  value={f.values.alias}
                  placeholder={`your-event-name`}
                  label="evt.live/"
                  labelStyle={{
                    textAlign: "right",
                    paddingRight: 0,
                    fontWeight: "bold",
                    paddingTop: Platform.OS === "web" ? 0 : 3,
                    color: "#3f5b7a",
                  }}
                  inputStyle={{ paddingLeft: 0 }}
                  onSubmitOnEnter={() => f.handleSubmit()}
                  autoCapitalize="none"
                  errorMessage={
                    f.errors.alias && f.touched.alias ? f.errors.alias : null
                  }
                  iconRightName={
                    f.values.alias && !f.dirty ? "copy-outline" : null
                  }
                  onIconRightAction={() => copy(f.values.alias)}
                />
              </>
            )}

            <Suspense fallback={<Text>Loading...</Text>}>
              <EventPersonalizeUpload event={event} />
            </Suspense>
          </>
        )}
      </Formik>
      {(event.event_status === "past" || event.datetime_uploaded) && (
        <View style={{ marginBottom: 40 }}>
          <EventVideoOptionsForm event={event} />
        </View>
      )}
    </>
  );
};

export default EventPersonalizeForm;
