import { Container, Text, Button, Toast } from "native-base";
import React, { useState } from "react";
import { ActivityIndicator, Platform, StyleSheet, View } from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import Bullet from "../../shared/Bullet";
import DashboardContent from "../../shared/DashboardContent";
import { useBuy } from "../../BuyContext";

const styles = StyleSheet.create({
  sublabelLeft: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
    textAlign: "right",
  },
  sublabel: {
    fontSize: 18,
    flex: 4,
  },
});

const BuyCredits = ({ navigation, route }) => {
  const loading = false;
  const auth = useAuth();
  const { broadcaster } = auth;
  const { purchase, getCurrencyPrice } = useBuy();

  const products = [
    {
      qty: 5,
      priceStripe: "price_1KsUCxCucVzS4gqKi1lc87Ey",
      priceMobile: "package_5",
      platforms: ["ios", "android", "web"],
    },
    {
      qty: 10,
      priceStripe: "price_1JkFFhCucVzS4gqKBxVVdXQA",
      priceMobile: "package_10",
      platforms: ["ios", "web"],
    },
    {
      qty: 25,
      priceStripe: "price_1JkFFhCucVzS4gqKx3nnjHfi",
      priceMobile: "package_25",
      platforms: ["ios", "web"],
    },
    // {
    //   qty: 50,
    //   priceStripe: "price_1JkFFhCucVzS4gqKlqNF65Ha",
    //   priceMobile: "package_50",
    //   webOnly: true,
    // },
  ];

  return (
    <Container>
      <AppHeader />
      <DashboardContent>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`Purchase Streaming Credits`}
              style={{
                textAlign: "left",
                marginBottom: 20,
              }}
            />

            <View style={{ marginVertical: 20 }}>
              <Bullet condensed>Unlimited viewers per event</Bullet>
              <Bullet condensed>
                Up to 24 hours HD broadcasting per event
              </Bullet>
              <Bullet condensed>Use for client video delivery</Bullet>
              <Bullet condensed>White-labeling</Bullet>
              <Bullet condensed>Embed on your website</Bullet>
              <Bullet condensed>Unlimited email and chat support</Bullet>
              <Bullet condensed>Credits do not expire</Bullet>
            </View>

            <AppTitle
              text={`Choose number of events`}
              style={{
                textAlign: "left",
                fontSize: 18,
                marginBottom: 10,
              }}
            />

            {!!broadcaster && (
              <>
                {products.map(
                  (p) =>
                    p.platforms.includes(Platform.OS) && (
                      <Button
                        key={p.priceStripe}
                        rounded
                        block
                        style={[
                          {
                            borderRadius: 14,
                            paddingLeft: 41,
                            paddingRight: 41,
                            marginTop: 10,
                            marginBottom: 10,
                            height: 62,
                            display: "flex",
                          },
                        ]}
                        bordered
                        onPress={() =>
                          purchase({
                            productId: p.priceMobile,
                            // Platform.OS === "web"
                            //   ? p.priceStripe
                            //   : p.priceMobile,
                            onComplete: () => {
                              Toast.show({
                                text: "Thank you!",
                                position: "top",
                                type: "success",
                                style: { marginTop: 30 },
                              });
                              navigation.navigate("Profile");
                            },
                          })
                        }
                      >
                        <Text style={styles.sublabelLeft}>{p.qty}</Text>
                        <Text style={styles.sublabel}>
                          {getCurrencyPrice(
                            p.priceMobile
                            // Platform.OS === "web"
                            //   ? p.priceStripe
                            //   : p.priceMobile
                          )}
                        </Text>
                      </Button>
                    )
                )}
              </>
            )}
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default BuyCredits;
