import * as Clipboard from "expo-clipboard";
import * as Linking from "expo-linking";
import { Button, Container, Text, Toast, Root } from "native-base";
import React, { useCallback, useState } from "react";
import { Platform, View } from "react-native";
import { useClipboard } from "use-clipboard-copy";

import { useAuth } from "../../AuthContext";
import { isWeb } from "../../globalUtils";
import AppHeader from "../../shared/AppHeader";
import AppInput from "../../shared/AppInput";
import { TextLink } from "../../shared/TextLink";
import AppTitle from "../../shared/AppTitle";
import DashboardContent from "../../shared/DashboardContent";

const VideoWall = () => {
  const { broadcaster } = useAuth();

  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  });

  const clipBoardCallback = (value) => () => {
    if (isWeb) {
      clipboard.copy(value);
    } else {
      Clipboard.setString(value);
    }
    Toast.show({
      text: "Copied",
      position: "bottom",
      type: "success",
    });
  };

  const videoWallLink = `https://evt.live/streams/${broadcaster}`;

  const linkPress = useCallback(() => {
    if (Platform.OS === "web") {
      window.open(videoWallLink, "_blank");
    } else Linking.openURL(videoWallLink);
  }, [videoWallLink]);

  const embedCode = `<iframe src="${videoWallLink}" width="100%" height="100%" style="margin:0 auto;" allowfullscreen allowtransparency="true" frameborder="0"></iframe>`;

  return (
    <Root>
      <Container>
        <AppHeader />
        <DashboardContent>
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`Video Wall`}
              style={{
                textAlign: "left",
                marginVertical: 20,
              }}
            />

            <Text>
              Your Video Wall includes all but the unpublished events on your
              account. You can share it with clients or embed it on your
              website.
            </Text>

            <TextLink
              text="View my Video Wall page"
              onPress={linkPress}
              style={{
                justifyContent: "flex-start",
                marginBottom: 20,
                marginTop: 10,
              }}
            />

            <AppInput
              value={videoWallLink}
              disabled={true}
              labelStyle={{ textAlign: "right" }}
              iconRightName={"copy-outline"}
              onIconRightAction={clipBoardCallback(videoWallLink)}
            />

            <AppTitle
              text={`Embed Video Wall into your website`}
              style={{
                textAlign: "left",
                marginTop: 60,
              }}
            />

            <AppInput
              textarea
              rowSpan={4}
              value={embedCode}
              disabled
              inputStyle={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                paddingLeft: 0,
              }}
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                marginTop: 32,
              }}
              iconRightName={"copy-outline"}
              onIconRightAction={clipBoardCallback(embedCode)}
            />
          </View>
        </DashboardContent>
      </Container>
    </Root>
  );
};

export default VideoWall;
