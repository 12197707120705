import moment from "moment-timezone";
import { Text } from "native-base";
import React, { useEffect, useState } from "react";

import { getTimeLeft } from "../globalUtils";
import { AppLargeGoLiveButton } from "./Buttons";
import useNow from "../hooks/useNow";

const GoLiveButton = ({ goLive, event, contentBeforeReady, style }) => {
  const [goliveloading, setgoliveloading] = useState(false);

  const {
    stream_key,
    is_published,
    is_test,
    event_window_start_datetime_utc,
    event_window_end_datetime_utc,
    event_status,
  } = event;

  const now = useNow();

  // const streamingAvailable =
  //   is_published &&
  //   now.isBetween(
  //     event_window_start_datetime_utc,
  //     event_window_end_datetime_utc
  //   ) &&
  //   event_status !== "live";

  // const isBefore = now.isBefore(event_window_start_datetime_utc);
  const isAfter = now.isAfter(event_window_end_datetime_utc);

  const testExpired = is_test && isAfter;
  const eventNeverStreamed =
    isAfter && is_published && !is_test && event_status === "upcoming";

  const liveButtonTitle = is_test
    ? "Test Live"
    : event_status === "upcoming"
    ? "Go Live"
    : "Continue";

  const buttonAction = async () => {
    // setgoliveloading(true);
    await goLive();
    // setgoliveloading(false);
  };
  return (
    <>
      {is_published && (
        <AppLargeGoLiveButton
          title={liveButtonTitle}
          style={style}
          onPress={buttonAction}
          disabled={!!goliveloading}
        />
      )}

      {/* {is_published && isBefore && (
        <>
          <AppLargeGoLiveButton
            style={{ marginBottom: 0 }}
            // disabled={true}
            onPress={buttonAction}
            // title={`Go Live in ${getTimeLeft(
            //   moment(event.event_datetime_utc).subtract(1, "hour")
            // )}`}
            title={`Test Live`}
          />
          {contentBeforeReady}
        </>
      )} */}

      {testExpired && (
        <Text
          style={{
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Test event expired. Please create a new one.
        </Text>
      )}

      {eventNeverStreamed && (
        <Text
          style={{
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          This event has not gone Live. Please reschedule in the settings.
        </Text>
      )}
    </>
  );
};

export default GoLiveButton;
