import moment from "moment-timezone";
import {
  fullBrowserVersion,
  isBrowser,
  isIOS,
  isMacOs,
} from "react-device-detect";
import { Platform, Linking } from "react-native";
// import * as mime from "react-native-mime-types";
import branch from "../branchlib";

export const openLink = async (url) => {
  if (Platform.OS === "web") {
    document.location.href = url;
  } else {
    await Linking.openURL(url);
    // await WebBrowser.openBrowserAsync(url);
  }
  return true;
};

export const IsAppleUniverse = isIOS || isMacOs || Platform.OS === "ios";

export const IsWebFull = isBrowser && fullBrowserVersion !== "none";

export const isWeb = Platform.OS === "web";

console.log(" Full Browser = >", fullBrowserVersion);
console.log(" IsAppleUniverse = >", IsAppleUniverse);
console.log(" isWeb = >", isWeb);
console.log(" IsWebFull = >", IsWebFull);

export const phoneThreshold = 665; //900;
export const minDesktopWidth = phoneThreshold;

export const formatEventDate = (utc, timezone) =>
  utc && timezone && moment(utc).tz(timezone).format("MMM Do YYYY, h:mm a");

export const formatEventDateOnly = (utc, timezone) =>
  utc && timezone && moment(utc).tz(timezone).format("MMM Do YYYY");

export const eventTypes = [
  { value: "wedding", label: "Wedding", icon: "ios-heart" },
  { value: "funeral", label: "Funeral", icon: "flower" },
  { value: "birthday", label: "Birthday", icon: "ios-gift" },

  { value: "school", label: "School", icon: "ios-school" },
  { value: "sport", label: "Sport", icon: "ios-football" },
  { value: "other", label: "Other", icon: "ios-ellipsis-horizontal" },
  // { value: "memorial", label: "Memorial / Celebration of Life" },
  // { value: "engagement", label: "Engagement" },

  // { value: "graduation", label: "Graduation" },
  // { value: "recital", label: "Recital" },
  // { value: "presentation", label: "Presentation" },
  // { value: "seminar", label: "Seminar" },
  // { value: "business", label: "Business" },
  // { value: "performance", label: "Performance" },
];

export const timezones = moment.tz
  .names()
  .map((tz) => ({ name: tz, value: tz }));

// export const getNameFromFile = (fileuri) => {
//   return mime.(fileuri);
// };

// export const getContentTypeFromFile = (fileuri) => {
//   return mime.lookup(fileuri);
// };

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getTimeLeft = (event_datetime_utc) => {
  const now = moment();
  const then = moment(event_datetime_utc);

  return moment.duration(now.diff(then)).humanize();
};

export const roles = [
  {
    value: "videographer",
    label: "Audio/Video Professional",
  },
  // {
  //   value: "photographer",
  //   label: "Photographer",
  // },
  {
    value: "funeralhome",
    label: "Funeral Home Owner / Director",
  },
  {
    value: "eventplanner",
    label: "Event Planner / Venue manager",
  },
  {
    value: "church",
    label: "Church / Pastor / Preacher / Minister",
  },
  {
    value: "school",
    label: "Teacher / School Manager",
  },
  {
    value: "nonprofit",
    label: "Non-profit / Charity",
  },
  {
    value: "livestreamer",
    label: "Live Event Streamer /  Producer",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const filterBranchParams = (params) => {
  const metadata = Object.keys(params)
    .filter(
      (key) =>
        key.indexOf("+") !== 0 &&
        key.indexOf("~") !== 0 &&
        key.indexOf("$") !== 0
    )
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  return metadata;
};

export const getUrlFromBranch = (params) => {
  const urlparams = filterBranchParams(params);
  const strParams = new URLSearchParams(urlparams).toString();
  return `${params.$canonical_url}${strParams ? `?` + strParams : ""}`;
};

export const makeSlug = (text) => {
  let slug = text.replace(/\W+/g, "-").toLowerCase().replace(/\-+$/gm, "");
  if (slug === "") {
    slug = "event" + Math.floor(100000 + Math.random() * 900000);
  }
  return slug;
};

export const collectMetadataFromBranchOrURL = async (branchdata) => {
  try {
    let metadata;
    // Parse web or branch links for UTM, referrals, or params to pass for Joy integration
    if (Platform.OS === "web") {
      const utmParamQueryString = new URLSearchParams(window.location.search);
      metadata = Object.fromEntries(utmParamQueryString);
    } else {
      const params = await branch.getLatestReferringParams();
      metadata = filterBranchParams(params);
    }

    const gapassed = branchdata?._ga || metadata._ga;
    const fbppassed = branchdata?._fbp || metadata._fbp;
    if (fbppassed) {
      metadata.fbp = fbppassed;
      metadata.fbc = branchdata?._fbc || metadata._fbc;
    }
    if (gapassed) {
      const ga = gapassed.split("-").pop();
      metadata.ga = ga;

      // TODO: save gclid? Save UTM from branch?

      // Alternative with _gl:
      // const split = branchdata?._gl.split("*");
      // const encoded = split[split.indexOf("_ga")+1];
      // console.log(atob(encoded.replace(/\.$/, "")));
      //
    } else {
      if (Platform.OS === "web") {
        const getCookie = function (name) {
          var match = document.cookie.match(
            new RegExp("(^| )" + name + "=([^;]+)")
          );
          if (match) return match[2];
          else return "";
        };

        const gacookie = getCookie("_ga")
          .replace("GA1.2.", "")
          .replace("GA1.1.", "");
        // GA1.2.670622894.1651695890

        console.log("getting ga cookie", gacookie);

        const fbp = getCookie("_fbp");
        const fbc = getCookie("_fbc");

        metadata.ga = gacookie;
        metadata.fbp = fbp;
        metadata.fbc = fbc;
      }
    }

    metadata.registered_from = Platform.OS;
    metadata.timezone = moment.tz.guess();

    return metadata;
  } catch (err) {
    console.error(err);
  }
};

export const makeUrl = (event, custom_domain = false) => {
  const domain = custom_domain || `https://evt.live/`;
  return event.alias
    ? `${domain}${event.alias}`
    : `${domain}${event.broadcaster}/${event.slug}`;
};

export const makeDownloadUrl = (event, custom_domain = false) => {
  const domain = custom_domain || `https://evt.live/`;
  return event.alias
    ? `${domain}${event.alias}/download`
    : `${domain}${event.broadcaster}/${event.slug}/download`;
};
