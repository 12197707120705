
import React, { useEffect, useState } from "react";

import { useAuth } from "../AuthContext";

const Feedback = () => {

  const { 
    credits,
    pmf_filled,
    publishedEvents,
    username,
    email
  } = useAuth();
  

  useEffect(() => {
    const cancelledOrFilled = window.sessionStorage.getItem("pmf_filled");
    if (publishedEvents >= 3 && !pmf_filled && cancelledOrFilled !== "true") {
      // Example: opening a popup as a centered modal
      window?.Tally.openPopup('meqYLo', {
        layout: 'default',
        autoClose: 5000,
        hideTitle: true,
        hiddenFields: {
          u: username,
          email
        },
        width: 376,
        "emoji": {
          "text": "👋",
          "animation": "wave"
        },
        onClose: () => { 
          window.sessionStorage.setItem("pmf_filled", true);
        },
        onSubmit: (payload) => {
          console.log(payload)
          window.sessionStorage.setItem("pmf_filled", true);
        }
      });
    }
  }, [publishedEvents, pmf_filled])  


  return null;

  // <script>
  //     Tally.openPopup('meqYLo', {
  //     layout: 'modal', // Open as a centered modal
  //     width: 700, // Set the width of the modal
  //     autoClose: 5000, // Close the popup 5 seconds after form was submitted (in ms)
  //     });
  // </script>

}

export default Feedback;