import * as WebBrowser from "expo-web-browser";
import { Text, Root, Container } from "native-base";
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  View,
  Linking,
  Platform,
  useWindowDimensions,
  NativeModules,
  DeviceEventEmitter,
  NativeEventEmitter,
} from "react-native";
import QRCode from "react-qr-code";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useAuth } from "@/components/AuthContext";
import { StyleConstants } from "@/components/globalStyle";
import { isWeb, minDesktopWidth } from "@/components/globalUtils";

import { TextLink } from "@/components/shared/TextLink";
import OnboardingButton from "@/components/shared/OnboardingButton";
import { GetAppStore, GetPlayStore } from "@/components/shared/Buttons";
import ShareEventButton from "@/components/shared/ShareEventButton";
import Link from "@/components/shared/Link";
import RtmpForm from "@/components/shared/RtmpForm";
import useSubscribeToEventUpdates from "@/components/hooks/useSubscribeToEventUpdates";
import DashboardContent from "@/components/shared/DashboardContent";
import AppHeader from "@/components/shared/AppHeader";
import { Ionicons } from "@expo/vector-icons";
import { createTest } from "@/components/http/events";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import { LargeUnlockButton, LiveIndicator } from "@/components/shared/Buttons";
import { openStreamWindow } from "@/components/Utils";

const styles = StyleSheet.create({
  title: {
    color: "#000000",
    fontFamily: StyleConstants.defaultTitleFont,
    fontSize: 42,
    fontWeight: "800",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: isWeb ? "normal" : null,
    lineHeight: 48,
    marginBottom: 12,
  },
  subtitle: {
    fontFamily: StyleConstants.defaultFont,
    opacity: 0.5,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: isWeb ? "normal" : null,
  },
  link: {
    color: "#e94a23",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: -0.2,
    lineHeight: 15,
  },
  buttonRow: {
    flexDirection: "row",
    flex: 0,
    marginTop: 40,
    marginBottom: 40,
  },
  leftCol: {
    width: 376,
    alignSelf: "flex-start",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: 20,
  },
});

const openLink = async (url) => {
  await WebBrowser.openBrowserAsync(url);
};

const TestScreen = ({ route, navigation }) => {
  const {
    slug,
    event: initialEvent,
    relatedEvent,
    rtmpActive: rtmpActiveInitial,
  } = route.params || {};

  const {
    broadcaster,
    profileLoaded,
    prefer_multiple_tests,
    getOpenAppFromWebUrl,
    custom_domain,
  } = useAuth();

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const [testEvent, setTestEvent] = useState(
    typeof initialEvent === "object" ? initialEvent : { broadcaster, slug }
  );
  const listenerRef = useRef(null);

  const [rtmpActive, setRtmpActive] = useState(rtmpActiveInitial);
  const [magicUrl, setmagicUrl] = useState("");
  const [streamWindowOpened, setStreamWindowOpened] = useState(false);

  useEffect(() => {
    (async () => {
      if (!slug && !initialEvent && profileLoaded) {
        const e = await createTest(broadcaster, !!prefer_multiple_tests);
        setTestEvent(e);
      }
    })();
  }, [slug, initialEvent, broadcaster, prefer_multiple_tests, profileLoaded]);

  const { event, loading } = useSubscribeToEventUpdates(testEvent, true);

  useEffect(() => {
    const openStream = async () => {
      if (event && event.stream_key && !streamWindowOpened) {
        if (Platform.OS !== "web") {
          setStreamWindowOpened(true);
          await openStreamWindow(event);
        }
      }
    };

    openStream();
  }, [event, streamWindowOpened]);

  useEffect(() => {
    if (Platform.OS === "web" && event && event.slug) {
      const fetchMagicUrl = async () => {
        const redir = await getOpenAppFromWebUrl(`/getapp/${event.slug}`);
        setmagicUrl(redir);
      };

      fetchMagicUrl();
    }
  }, [event]);

  // Effect for adding the event listener
  useEffect(() => {
    const addEventListener = async () => {
      if (
        event &&
        event.stream_key &&
        Platform.OS !== "web" &&
        !listenerRef.current
      ) {
        const emitter =
          Platform.OS === "ios"
            ? new NativeEventEmitter(NativeModules.StartStream)
            : new NativeEventEmitter(NativeModules.StreamModule);

        console.log("adding listener");
        if (listenerRef.current) {
          console.log("Remove current listener");
          listenerRef.current.remove();
        }
        listenerRef.current = emitter.addListener(
          "StreamDone",
          async (data) => {
            console.log("STREAM DONE", data);
            const reviewAsked = await AsyncStorage.getItem("reviewAsked");
            if (!reviewAsked) {
              navigation.navigate("AskReview", { relatedEvent });
              await AsyncStorage.setItem("reviewAsked", "true");
            } else {
              if (relatedEvent) {
                navigation.replace("PayWall", {
                  event: relatedEvent,
                  slug: relatedEvent.slug,
                });
              } else {
                navigation.navigate("EventList");
              }
            }
          }
        );
      }
    };

    addEventListener();

    return () => {
      if (listenerRef.current) {
        console.log("removing listener");
        listenerRef.current.remove();
        listenerRef.current = null;
      }
    };
  }, [event?.stream_key]);

  const domain = custom_domain || `https://evt.live/`;
  const url = event?.alias
    ? `${domain}${event.alias}`
    : `${domain}${broadcaster}/${event?.slug}`;

  const linkPress = useCallback(() => {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else Linking.openURL(url);
  }, [url]);

  const openAppStore = async () => {
    await openLink(
      magicUrl ||
        "https://apps.apple.com/us/app/eventlive-event-live/id1457137042#?platform=iphone"
    );
  };

  const openPlayStore = async () => {
    await openLink(
      "https://play.google.com/store/apps/details?id=pro.eventlive&hl=en&gl=US"
    );
  };

  const isLive = event?.event_status === "live";

  const titleFontSizeStyle =
    Platform.OS == "web" ? { fontSize: 42 } : { fontSize: 24, lineHeight: 30 };

  return (
    <Root>
      <Container>
        <AppHeader
          // title={title}
          leftButtonComponent={
            <Pressable
              onPress={() => {
                navigation.navigate("EventList");
              }}
            >
              <Ionicons
                name="ios-close-outline"
                size={30}
                style={{ opacity: 0.3 }}
              />
            </Pressable>
          }
          hasLeftButton={true}
          hasRightButton={true}
          renderRightButton={() => {
            return loading && Platform.OS === "web" && <AppActivityIndicator />;
          }}
        />

        <DashboardContent>
          <View
            style={{
              flex: 1,
              flexGrow: 1,
              marginHorizontal: 0,
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {Platform.OS !== "web" && loading && (
              <AppActivityIndicator size="large" color="#F84123" />
            )}
            {Platform.OS === "web" && (
              <View style={{ flex: 1 }}>
                {isLive && (
                  <View style={{ padding: 20, flex: 1 }}>
                    <Text style={styles.title}>You are Live!</Text>

                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        maxWidth: 800,
                      }}
                    >
                      <OnboardingButton
                        onPress={linkPress}
                        text="Watch Live"
                        style={{ width: "100%" }}
                      />

                      <ShareEventButton
                        url={url}
                        title={event?.title}
                        text="Share"
                        dark
                        bordered
                        style={{ width: "100%", alignSelf: "center" }}
                      />

                      <TextLink
                        text={url}
                        onPress={linkPress}
                        textStyle={{ fontSize: 14, paddingVertical: 5 }}
                      />
                    </View>
                  </View>
                )}

                {!isLive && (
                  <>
                    <View style={{ paddingHorizontal: 20 }}>
                      <Text style={[styles.title, titleFontSizeStyle]}>
                        {rtmpActive
                          ? `RTMP Settings for ${event?.title}`
                          : "Let's set up your mobile app"}
                      </Text>

                      <Text style={styles.subtitle}>
                        Our native iOS and Android apps are the easiest way to
                        go live.
                      </Text>
                      {Platform.OS === "web" && (
                        <Link onPress={() => setRtmpActive(!rtmpActive)}>
                          {rtmpActive
                            ? "Get EventLive app to go Live"
                            : "Alternative ways to stream"}
                        </Link>
                      )}
                    </View>

                    {rtmpActive && (
                      <View style={{ paddingHorizontal: 20 }}>
                        <RtmpForm {...event} />
                      </View>
                    )}

                    {!rtmpActive && Platform.OS === "web" && (
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View style={styles.leftCol}>
                          {isMobileUX && magicUrl && (
                            <View>
                              <LargeUnlockButton
                                text="Open our App to Go Live"
                                onPress={() => {
                                  Linking.openURL(
                                    magicUrl ||
                                      `https://eventlive.app.link/streamFor?streamKey=${event?.stream_key}&$ios_nativelink=true`
                                  );
                                }}
                              />
                            </View>
                          )}

                          {!isMobileUX && magicUrl && (
                            <View>
                              <Pressable onPress={openAppStore}>
                                <QRCode
                                  value={magicUrl}
                                  style={{ maxWidth: 200 }}
                                />
                              </Pressable>
                              <View style={styles.buttonRow}>
                                <GetAppStore
                                  onPress={openAppStore}
                                  style={{ marginRight: 10 }}
                                />

                                <GetPlayStore onPress={openPlayStore} />
                              </View>
                            </View>
                          )}
                        </View>
                        <Image
                          source={require("@/assets/getapp.png")}
                          resizeMode="contain"
                          style={{
                            width: "100%",
                            height:
                              Math.min(responsiveWidth, 720) * (463 / 637),
                            alignSelf: "center",
                          }}
                        />
                      </View>
                    )}
                  </>
                )}
              </View>
            )}
          </View>
        </DashboardContent>
      </Container>
    </Root>
  );
};

export default TestScreen;
