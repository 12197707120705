import * as Clipboard from "expo-clipboard";
import { useClipboard } from "use-clipboard-copy";

import { Toast, Text, Button } from "native-base";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { isWeb } from "../globalUtils";

import AppTitle from "./AppTitle";
import RtmpForm from "./RtmpForm";

const HowToStream = ({ event }) => {
  const { stream_key } = event;
  const navigation = useNavigation();
  const clipboard = useClipboard({
    copiedTimeout: 1000,
  });

  const clipBoardCallback = (value) => () => {
    if (isWeb) {
      clipboard.copy(value);
    } else {
      Clipboard.setString(value);
    }
    Toast.show({
      text: "Copied",
      position: "bottom",
      type: "success",
    });
  };

  const broadcastLink = `https://eventlive.app.link/streamFor?streamKey=${stream_key}&$ios_nativelink=true`;

  return (
    <View style={{ margin: 20 }}>
      <AppTitle text={"Using EventLive App"} style={{ textAlign: "left" }} />
      <View style={{ marginBottom: 40 }}>
        <Text style={{ paddingVertical: 10 }}>
          To go Live, all you need is the app, and it's recommended to use a
          smartphone or tablet tripod for stability. You can start streaming as
          early as 2 hours before the scheduled time. Feel free to test anytime
          using Test Live button.
        </Text>
      </View>

      <AppTitle text={"Using RTMP"} style={{ textAlign: "left" }} />
      <View style={{ marginBottom: 40 }}>
        <Text style={{ paddingVertical: 10 }}>
          You can go live from computer, certain cameras, encoders and other
          professional equipment using RTMP settings below. Pass them along to
          your videographer so they can broadcast on your behalf
        </Text>

        {event.is_published && <RtmpForm {...event} />}
        {!event.is_published && (
          <Button
            onPress={() => {
              navigation.navigate("PayWall", { event, slug: event.slug });
            }}
          >
            <Text>Buy now to Unlock RTMP access</Text>
          </Button>
        )}
      </View>

      <AppTitle
        text={"Delegate Live Streaming"}
        style={{ textAlign: "left" }}
      />
      <View style={{ marginBottom: 40 }}>
        <Text style={{ paddingVertical: 10 }}>
          You can invite a Guest Broadcaster to your event, who can go live
          without needing to sign in or sign up; the invitation link will
          directly lead them to the app and open your event. Only one device can
          stream at a time. The guest broadcaster won't have permissions to
          alter settings.
        </Text>
        <Button
          onPress={clipBoardCallback(broadcastLink)}
          rounded
          primary
          bordered
          dark
        >
          <Text>Invite a Guest Broadcaster</Text>
        </Button>
      </View>
    </View>
  );
};

export default HowToStream;
