import { Container, Button, Text } from "native-base";
import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  View,
  Platform,
  Linking,
} from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import Bullet from "../../shared/Bullet";
import DashboardContent from "../../shared/DashboardContent";
// import { openLink } from "../../../globalUtils";

const environment = process.env.NODE_ENV;

const subs = [
  {
    title: "Annual *Get 2 months free!",
    price: "$124/mo. Billed at $1488 annually",
    url_test: "https://buy.stripe.com/test_dR63ct8Dcdojc2Q6op",
    url_prod: "https://buy.stripe.com/eVadRm90mg8r1l69AI",
  },
  {
    title: "Monthly",
    price: "$148/mo. Billed monthly",
    url_test: "https://buy.stripe.com/test_6oEbIZg5E1FB4Ao9AC",
    url_prod: "https://buy.stripe.com/eVa6oU90mbSb9RCcMV",
  },
];

const buttonStyle = {
  borderRadius: 14,
  paddingLeft: 41,
  paddingRight: 41,
  marginTop: 10,
  marginBottom: 10,
  height: 82,
  display: "flex",
  flexDirection: "column",
};
const BuyCredits = ({ navigation, route }) => {
  const [loading, setloading] = useState(false);
  const auth = useAuth();
  const { broadcaster, email } = auth;
  return (
    <Container>
      <AppHeader />
      <DashboardContent>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 44,
              paddingHorizontal: 16,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={`Subscribe to EventLive Unlimited`}
              style={{
                textAlign: "left",
                marginBottom: 20,
              }}
            />

            <View style={{ marginVertical: 20 }}>
              <Bullet condensed>Create Unlimited Events</Bullet>
              <Bullet condensed>
                Storage is included for as long as you're subscribed
              </Bullet>
              <Bullet condensed>Per-event white-labeling</Bullet>
              <Bullet condensed>Embed on your website</Bullet>
              <Bullet condensed>Unlimited email and chat support</Bullet>
            </View>

            {!!broadcaster && (
              <>
                {subs.map((s) => (
                  <Button
                    key={s.title}
                    rounded
                    block
                    style={[buttonStyle]}
                    bordered
                    onPress={async () => {
                      const url =
                        (environment === "production"
                          ? s.url_prod
                          : s.url_test) +
                        `?prefilled_email=${email}&client_reference_id=${broadcaster}`;
                      if (Platform.OS === "web") {
                        document.location.href = url;
                      } else {
                        await Linking.openURL(url);
                      }
                    }}
                    disabled={!!loading}
                  >
                    {loading && <ActivityIndicator color="#ccc" />}
                    <Text>{s.title}</Text>
                    <Text style={{ paddingTop: 10 }}>{s.price}</Text>
                  </Button>
                ))}
              </>
            )}
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default BuyCredits;
